@use './variables' as global;

html{
    font-size: 62.5%;
    scroll-behavior: smooth;
}

html,
body,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video{
    color: global.$text-color;
    font-weight: 400;
    margin: 0;
    padding: 0;
    border: 0;
    letter-spacing: normal;
    vertical-align: baseline;
}

.brand-bg{
    background-color: global.$brand-red-light;
}

.selected {
    background-color: #9C0010 !important;
    color: #FFF !important;
}

.slot_time {
    width: 150px !important;
    margin: 10px !important;
}

ul{
    @extend pg;
    margin: 1rem;
    padding:0 0 0 20px;
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

a{
    @include global.link-underline(global.$brand-red, global.$brand-red-dark);
    @extend pg;
}

h1{
    @extend h;
    position: relative;
    font-weight: 700;
    margin-bottom: 40px;
    &::after {
        position: absolute;
        content: '';
        background: linear-gradient(to right, #b95f68 0%, #9c0010 100%);
        height: 6px;
        width: 45px;
        bottom: 0;
        left: 1px;
    }
    @media(width>global.$breakpoint-tablet) {
        &::after{
            height: 5px;
            bottom:-1px;
            width: 39px;
            left:2px;
        }
    }
    @media(width>=global.$breakpoint-desktop) {
        margin-bottom:45px;
        &::after {
            left: 1px;
            bottom: 4px;
            width: 45px;
            height: 6px;
        }
    }
}

h2{
    @extend hs;
    display: block;
    font-weight: 700;
}

h3{
    @extend hss;
    display: block;
    font-weight: 700;
}

h4 {
    @extend hsss;
    display: block;
    font-weight: 700;
}

p{
    @extend pg;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: justify;
    text-align-last: left;
    &:first-of-type {
        margin-top: 0;
    }
}

.text-justify {
    text-align: justify;
    text-justify: inter-word;
}

.MuiStepLabel-labelContainer span,
.MuiStepLabel-iconContainer .MuiStepIcon-text, 
.country-name,
.dial-code,
.MuiPickersCalendarHeader-label,
.MuiDayCalendar-weekDayLabel,
.MuiPickersDay-root,
.MuiDayCalendar-weekContainer button,
.MuiChip-label,
.MuiFormLabel-root,
label.MuiInputLabel-formControl,
input.MuiInputBase-input,
button.MuiPickersMonth-monthButton,
button.MuiPickersYear-yearButton {
    font-size: 1.8rem;
}

/*.active {
    visibility: visible !important;
    opacity: 1 !important;
    transform: scale(1) translateX(-40px) !important;
}

@media(max-width: global.$breakpoint-desktop) {
    .show,
    .active {
        display: block !important;
        transform: none !important;
    }
}
*/
