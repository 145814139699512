@use './variables' as global;

.brazao{
  width: 100%;
  max-width: 80px;
}
.middleSection{
  flex-grow: 1;
  h2{
    margin: 0;
    line-height: 1;
  }
  p{
    margin: 0;
    font-size: 2.16rem;
  }
}
.mapa {
  width: 100%;
  max-width: 160px;
  height: 90px;
}


.pageLocationCaption{
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  line-height: 1.4;
  margin-bottom: 0;
  text-overflow: ellipsis;
  svg{
    margin-right: 4px;
    border-bottom: 3px solid #b95f68;
    font-size: 2.2rem;
  }
}


@media (max-width: global.$breakpoint-desktop) {
  .header {
    display: none;
  }
  .locationWrapper{
    display: none !important;
  }
}
