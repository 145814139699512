@use './variables' as global;

.contacteNos{
  padding: 24px 0;
  margin-bottom: 64px;
}

.contactosWrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  margin-bottom: 36px;
  h3{
    text-align: center;
    margin: 0 0 10px;
    font-weight: 500;
    color: global.$black;
  }
  .linksColumn{
    display: grid;
    gap: 10px;
    text-align: center;
    margin-top: 16px;
    height: fit-content;
    a, p {
      display: inline-block;
      font-variant-numeric: tabular-nums;
      text-decoration: none;
      color: global.$text-color;
      margin: 0;
      text-align-last: center;
      svg{
        position: relative;
        top: 6px;
        margin-right: 8px;
        font-size: 2.2rem;
      }
    }
  }
  .imgDiv {
    display: none;
    margin: 0;
    padding: 0;

    img {
      width: 100%;
      display: none;
    }
  }
}

@media(width>global.$breakpoint-tablet){
  .contactosWrapper {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 25px;
    h3{
      text-align: left;
    }
    .linksColumn {
      text-align: left;
      a,p{
        text-align-last: left;
      }
      .oddOne {
        text-indent: -30px;
        margin-left: 27px;
      }
    }
    .imgDiv {
      display: none;

      img {
        display: none;
      }
    }
  }
}

@media(width>=global.$breakpoint-desktop) {
  .contactosWrapper {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 40px;

    .linksColumn {
      text-align: left;
    }

    .imgDiv {
      display: grid;

      img {
        display: block;
        position: relative;
        top: 20px;
      }
    }
  }
}
