@use '../../assets/css/variables' as global;

footer{
  background-color: global.$footer-primary-color;
  color: global.$footer-text-color;
  padding-top: 30px;
  section{
    position: relative;
  }
}

section.top{
  background-color: global.$footer-primary-color;
  img {
    position: relative;
    margin: 0 auto 34px;
  }
  
  .links {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 36px;
  }
  .linksColumn{
    display: grid;
    gap: 10px;
    text-align: center;
    height: fit-content;
    a {
      text-decoration: none;
      color: global.$footer-text-color;
      font-variant-numeric: tabular-nums;
      text-wrap: nowrap;
  
      svg {
        position: relative;
        top: 6px;
        margin-right: 8px;
        font-size: 2.2rem;
      }
    }
  }
  h3{
    margin: 0 0 10px;
    font-weight: 500;
    color: global.$footer-text-color;
  }
  p {
    margin: 0;
  }
  .socials{
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 12px;
    height: 56px;
    &>a{
      background-color: global.$brand-red-light;
      color: global.$footer-primary-color;
      width: 36px;
      height: 36px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      svg{
        font-size: 3rem;
      }
    }
  }
  .socialsColumn{
    display: grid;
    gap: 10px;
    text-align: center;
    height: fit-content;
    :is(h3,p){
      display: none;
    }
    p {
      color: global.$footer-text-color;
    }
  }
}

.bottomWrapper{
  background-color: global.$footer-copyright;
  
  .bottom{  
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
    text-align: center;
    .legal{
      >a{
        margin: 0 0 0 4px;
      }
      >span{
        margin-right: 10px;
      }
    }
    .legal, .copyright{
      font-size: 1.4rem;
      color: global.$footer-text-color;
      margin: 0;
    }
  }
}

@media(width>global.$breakpoint-tablet){
  section.top{
    .linksColumn{
      text-align: left;
      .oddOne{
        text-indent: -30px;
        margin-left: 27px;
      }
    }
    .links{
      grid-template-columns: repeat(2, 1fr);
      margin-right: 50px;
    }
    .socials{
      left: 0;
      transform: translate(0);
    }
    .socialsColumn{
      text-align: left;
      grid-column: span 2;
    }
    img{
      margin: 0 0 20px;
    }
  }
  .bottomWrapper
  .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    text-align: left;
  }
}

@media(width>=global.$breakpoint-desktop){
  footer{
    section{
      margin: 0 auto;
      //max-width: 580px;
      padding: 0;
    }

    section.top{
      img{
        left: 0;
        transform: translateX(0);
      }
      .links{
        grid-template-columns: repeat(3,1fr);
        align-items: flex-start;
        margin-inline:80px
      }
      .socials{
        align-items: flex-start;
        justify-self: flex-start;
      }
      .socialsColumn {
        grid-column: span 1;
        :is(h3, p) {
          display: block;
        }
      }
    }
  }
}
