@use 'sass:list';
//Ignora isto, são umas funções em sass para eu não ter de escrever muito css :)
@function get-breakpoint-property($prop, $bp) {
  @return map-get(map-get($bp-sizes, $bp), $prop);
}

@function pow($x, $y) {
  $ret: 1;

  @if $y >0 {
    @for $i from 1 through $y {
      $ret: calc($ret * $x);
    }
  }

  @else {
    @for $i from $y to 0 {
      $ret: calc($ret / $x);
    }
  }

  @return $ret;
}

@mixin generate-font-properties($label, $base-font-size, $base-line-height, $scale) {
  $scale-value: list.index($font-sizes, $label) - 1;

  $fs: $base-font-size * pow($scale, $scale-value);
  $lh: $base-font-size * calc($base-line-height / $fs);

  @while $lh < 1 {
    $lh: $lh + $lh;
  }

  font-size: $fs;
  line-height: $lh;
}

@mixin breakpoint($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Invalid breakpoint `#{$name}`.";
  }

  @else {
    @if map-get($breakpoints, $name)=='' {
      @content;
    }

    @else {
      @media (min-width: map-get($breakpoints, $name)) {
        @content;
      }
    }
  }
}

@mixin link-underline($text, $selection) {
    color: $text;
    text-decoration: underline;
    text-decoration-thickness: from-font;
    text-underline-offset: 2px;
    text-decoration-skip-ink: all;
    &:hover {
      color: $selection;
      text-decoration-thickness: 3px;
    }
}


// SCSS começa aqui 

$primary-color: #F3F3F3;
$secondary-color: #FFF;
$text-color: #565360;
$label-color: #908E9B;
$disabled-color: #908E9B;
$black: #000;
$red: #C33948;
$brand-red: hsla(354, 100%, 31%, 1);
$brand-red-dark: hsla(0, 67%, 23%, 1);
$brand-red-light: hsla(0, 100%, 96%, 1);
$brand-light-background: hsla(0, 43%, 99%, 1);
$footer-primary-color: #222222;
$footer-text-color: #ffffff;
$footer-copyright: #444444;

$font-family: 'Inter Variable', sans-serif;

$breakpoint-desktop: 769px;
$breakpoint-tablet: 480px;

$base-line-height: 1.4;
$base-font-size: 1.843rem;
$scale: 1.3;

$breakpoints: (
  small: 480px,
  medium: 769px,
  large: 1280px
);

$font-sizes: (
  pg,
  hsss,
  hss,
  hs,
  h,
  hero
);

$bp-sizes: (
  small: (base-font-size: 1.375rem,
    base-line-height: 1.4,
    scale: 1.25),
  medium: (base-font-size: 1.563rem,
    base-line-height: 1.5,
    scale: 1.4),
  large: (base-font-size: 1.7rem,
    base-line-height: 1.6,
    scale: 1.2)
);


@each $label in $font-sizes {
  %#{$label} {
    @include generate-font-properties($label, $base-font-size, $base-line-height, $scale);
  }
}

@each $size, $data in $bp-sizes {

  $bsf: get-breakpoint-property(base-font-size, $size);
  $blh: get-breakpoint-property(base-line-height, $size);
  $s: get-breakpoint-property(scale, $size);

  @include breakpoint($size) {
    @each $label in $font-sizes {
      #{$label} {
        @include generate-font-properties($label, $bsf, $blh, $s);
      }
    }
  }
}
