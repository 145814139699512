.servicoConsular{
  background-color: #fff;
}
.scContainer{
  background-color: rgba(156, 0, 16, 0.04);
  padding-top: 24px;
}
.scHeadline{
  margin: 70px 0 0 !important;
  text-align: center;
  text-align-last: center;
  font-weight: 500;
  font-size: 1.8rem;
}

.scCaption{
  font-weight: 500;
  margin: 16px 0 14px;
}

.verMaisBtn{
  margin-top: 10px;
  padding-left: 0;
  font-size: 1.8rem !important;
  text-transform: none !important;
  &:hover{
    background-color: #fff;
  }
}
