@use './variables' as global;

.header{
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.body{
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
  max-width: 810px;
  height: 111px;
  text-align: justify;
  text-align-last: left;
}

.caption{
  background-color: hsla(354, 100%, 31%, 1);
  color: #f3f3f3;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 8px;
}

.date{
  font-size: 1.2rem;
  text-transform: none;
  margin-right: 12px;
  svg{
    position: relative;
    top: 3px;
    margin-right: 4px;
  }
}
