@use './variables' as global;



#nav-menu{
  border-block: 1px solid #eee;
  background-color: global.$brand-red;
  position: sticky;
  top: 63px;
  z-index: 9;
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;
    height: 60px;

    ul {
      list-style: none;
    }
  
    a {
      text-decoration: none;
      color: global.$brand-light-background;
    }
  
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: global.$brand-light-background;
    }
  
    svg {
      font-size: 3rem;
    }

    .btn {
      display: block;
      background-color: global.$brand-red;
      color: global.$brand-light-background;
      text-align: center;
      padding: 0.6rem 1.4rem;
      //font-size: 1rem;
      font-weight: 500;
      border-radius: 5px;
    }
  
    .logo {
      //margin-right: 1.5rem;
      display: none;
      background-color: #fff;
      border-radius: 50%;
      padding: 4px;
      height: 45px;
      width: 45px;
      text-align: center;
      
    }
    .mobileTitle{
      display: none;
      margin-left: 1rem;
      color: global.$primary-color;
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 2rem;
    }
    .mobileSignature {
      display: none;
    }
    .menu{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
      margin: 0;
      padding: 0;
      height: 60px;
      .menu-bar{
        align-items: baseline;
        width: 100%;
        -webkit-hyphens: manual;
        hyphens: manual;
        margin: 0;
        padding: 0;
        height: 60px;
        li{
          &:first-child{
            .dropdown{
              flex-direction: initial;
              min-width: global.$breakpoint-tablet;
            }
          }
        }
        .nav-link{
          font-size: 2rem;
          font-weight: 600;
          padding: 0.3rem;
          //min-width: 60px;
          margin: 0 1rem;
          font-family: 'Inter Variable', sans-serif;
          &:hover{
            color: global.$brand-red-dark;
          }
          &.active-page{
            color: global.$brand-red-dark;
            border-bottom: 4px solid global.$brand-red-dark;
            height: 60px;
            display: flex;
            align-items: center;
            background-color: global.$secondary-color;
            padding: 0 14px;
            border-radius: 0 0 16px 16px;
          }
        }
        .dropdown-btn{
          display: flex;
          align-items: center;
          justify-content: space-around;
          gap: 0.15rem;
          font-size: 2rem;
          font-weight: 600;
          &.expanded{ 
            color: global.$brand-red-dark;
            svg{
            transform: rotate(180deg);
          }}
        }
        .dropdown-link{
          display: flex;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          border-radius: 4px;
          transition: 0.1s ease-in-out;
          color: global.$brand-red-light;
          &:hover{
            background-color: global.$brand-red-dark;
          }
        }
        .dropdown{
          display: flex;
          flex-direction: column;
          min-width: 230px;
          background-color: global.$brand-red;
          border-radius: 4px;
          position: absolute;
          top: 60px;
          z-index: 1;
          visibility: hidden;
          opacity: 0;
          transform: scale(0.97) translateX(-40px);
          transition: 0.1s ease-in-out;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
          &.active{
            visibility: visible;
            opacity: 1;
            transform: scale(1) translateX(-40px);
          }
          ul{
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: 1.2rem;
            //font-size: 0.95rem;
          }
        }
      }

      .right-container{
        display: flex;
        align-items: center;
        column-gap: 1rem;
        img{
          border-radius: 50%;
        }
      }      
    }
  }
}

.nav-start,
.nav-end,
.menu-bar,
.right-container {
  display: flex;
  align-items: center;
}

#hamburger {
  display: none;
  padding: 0.1rem;
  margin-left: 1rem;
  font-size: 1.9rem;
}

@media(max-width: global.$breakpoint-desktop){
  #hamburger{
    display: block;
  }
  #nav-menu {
    position: relative;
    top: 0;

    .container {
      padding: 1.2rem;
      .btn {
        
      }

      .logo {
        display: block;
      }

      .mobileTitle{
        display: block;
      }
      .mobileSignature{
        display: block;
        margin:0 auto;
        width: fit-content;
        .emergencyBtn {
          font-weight: 600;
          color: global.$brand-red-dark;
          border-color: global.$brand-red-dark;
          margin: 32px 0;
          font-size: 1.4rem;
        }
        .mobileBrasao{
          display: block;
          width: 208px;
        }
      }

      .menu {
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        min-height: 100vh;
        width: 100vw;
        background-color: global.$brand-light-background;
        transform: none;
        border: none;
        &.show{
          display: block;
        }
        .menu-bar {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          row-gap: 1rem;
          padding: 3rem;
          margin: 0;
          height: auto;
          li {
            &:first-child {
              border-right: none;
              .dropdown {
                min-width: 100%;
              }
            }

            &:nth-child(n+2) {
              
            }
          }
          &>li:not(:last-child){
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #eee;
          }

          ul:nth-child(1) {
            border-right: none;
            
          }

          .dropdown-link-title {
            
          }

          .nav-link {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-weight: 500;
            font-size: 1.8rem;
            margin: 0;
            color: global.$brand-red-dark;
            &:hover {
              color: global.$brand-red-dark;
            }
            &.active-page {
              color: global.$brand-red-dark;
              border-left: 3px solid global.$brand-red-dark;
              font-weight: 700;
              background-color: global.$secondary-color;
              border-bottom: none;
              height: auto;
              display: flex;
              align-items: center;
              padding: 0 0 0 6px;
              border-radius: 0;
            }
          }

          .dropdown-btn {
            font-weight: 500;
          }

          .dropdown-link {
            color: global.$brand-red-dark;
            font-weight: 500;
            &:hover {
              
            }

            p {
              
            }
          }

          .dropdown {
            display: none;
            min-width: 100%;
            border: none;
            border-radius: 0;
            position: static;
            top: 0;
            left: 0;
            visibility: visible;
            opacity: 1;
            transform: none;
            box-shadow: none;
            background-color: transparent;

            &.active {
              display: block;
              transform: none;
            }

            ul {
              padding-left: 0.3rem;
            }
          }
        }

        .nav-start,
        .nav-end,
        .menu-bar,
        .right-container {
          
        }

        .right-container {
          

          img {
            
          }
        }
      }
    }
  }
}
