@use '../../assets/css/variables' as global;

.slidesDestaquesContainer{
  height: 400px;
  .sliderDestaquesView{
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    padding: 0;
  }
}

.heroTitle{
  font-size: 4rem;
  text-shadow: 0 0 3px #000;
  color: #fff;
  margin-bottom: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.heroCaption{
  text-shadow: 0 0 3px #000;
  color: #fff;
  margin-bottom: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 100%;
  max-width: 820px;
  //height: 64px;
  text-align: justify;
  text-align-last: left;
}

.Button{
  display: block !important;
  text-transform: none !important;
  font-size: 1.6rem !important;
}

.controls{
  svg{
    font-size: 3rem;
  }
}

@media(width<global.$breakpoint-tablet){
  .slidesDestaquesContainer {
    height: 250px;
    .sliderDestaquesView {
      height: 250px;
    }
  }
  .heroTitle {
    font-size: 2rem;
    margin-bottom: 8px;
  }
  .controls {
    display: none !important;
  }
}
