@use './variables' as global;

.cookie_popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #f0f0f0;
    padding: 10px;
    text-align: center;
    justify-content: center;
  }

  div.cookie_popup > p {
    text-align: center;
    text-align-last: center;
  }
  
  .cookie_popup button {
    background: global.$brand-red;
    color: #fff;
    cursor: pointer;
  }