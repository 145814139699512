@use './_variables.scss' as global;

.verMaisBtn{
  position: absolute; 
  right: 6px; 
  font-size: 10px;
  text-transform: none;
}

.dateBtn{
  color: global.$black;
}

.newsCaption{
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-align: justify;
}

.newsHead{
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.newsCategory{
  background-color: global.$brand-red;
  font-size: 1.3rem;
  padding: 4px 6px;
  display: block;
  width: fit-content;
  position: absolute;
  top: 215px;
  color: global.$primary-color;
  font-weight: 600;
}

.newsDate{
  font-size: 1.2rem;
  display: inline-flex;
  align-items: flex-start;

  svg{
    margin-right: 4px;
  }
}

.newsBtn{
  font-size: 1.2rem !important;
  text-transform: none !important;
  position: absolute !important;
  right: 8px;
  bottom: 8px;
}

.moreNewsBtn{
  position: absolute;
  right: 0;
  margin-top: 8px;
 
  display: flex;
  align-items: center;
  svg{
    margin-left: 4px;
  }
}
