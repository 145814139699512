@use './_variables.scss' as global;

.topNav{
  margin:0;
  padding:0;
  position: sticky;
  top: 0;
  left: 0;
  background-color: global.$brand-red;
  z-index: 999;
}

.ntSocialMedia {

  a{
    color: global.$primary-color;
    svg{
      font-size: 2.2rem;
    }
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.ntEmergency {
  .emergencyBtn{
    font-weight: 600;
    color: global.$primary-color;
    border-color:global.$primary-color;
    margin: 12px 0 12px 16px;
    font-size: 1.3rem;
    &:hover{
      border-color: global.$primary-color;
    }
  }
  a{
    color: global.$primary-color;
    font-weight: 600;
    text-decoration: none;
    
    &:hover{
      text-decoration: underline;
    }
    svg {
      vertical-align: middle;
      margin-right: 4px;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

@media (max-width: global.$breakpoint-tablet) {
  .topNav{
    display: none;
  }
}
