@use './_variables.scss' as global;

.dateBadge{
  display: inline-table;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  margin: 12px;
  padding: 8px;
  border-radius: 50%;
  background-color: global.$brand-red;
  color: global.$secondary-color;
  .dateCaption{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
  }
}
